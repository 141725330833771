import firebase from "@firebase/app";
import "@firebase/firestore";

const config = {
  apiKey: "AIzaSyDrH44iejr5y9e6y0yKF3L-omUzvLtXuO0",
  authDomain: "frogenchtop.firebaseapp.com",
  databaseURL: "https://frogenchtop.firebaseio.com",
  projectId: "frogenchtop",
  storageBucket: "frogenchtop.appspot.com",
  messagingSenderId: "296996021256",
  appId: "1:296996021256:web:db459f0284770136c2ddbd",
};

firebase.initializeApp(config);

export const db = firebase.firestore();

export default firebase;
