import { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useBeforeunload } from "react-beforeunload";
import sf from "seconds-formater";
import ReactPlayer from "react-player/youtube";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  CloseIcon,
  CheckIcon,
} from "@chakra-ui/icons";
import { Howl } from "howler";
import { createStore, createHook, defaults } from "react-sweet-state";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import throttle from "lodash.throttle";
import {
  useToast,
  Button as ChakraButton,
  RadioGroup,
  Radio,
  Stack,
  Select,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
} from "@chakra-ui/react";
import slugify from "./slugify";
import GameTheme from "./theme/Game";
import { db } from "./config";

const syncToBase = throttle((state) => {
  db.doc(state.teamPath).set(state, {
    merge: true,
  });
}, 500);

const firestore = (storeState) => (next) => (arg) => {
  const result = next(arg);

  if (!storeState.getState().isSpectator && storeState.getState().teamPath) {
    const { scoresById, ...nextState } = storeState.getState();
    syncToBase(nextState);
  }

  return result;
};

defaults.middlewares.add(firestore);

const ding = new Howl({
  src: ["/sounds/ding.mp3"],
});

const tictac = new Howl({
  src: ["/sounds/tictac.mp3"],
  loop: true,
});

export const questions = {
  1: {
    question: "What does Miss Frogenchtop put on her pancakes ? ",
    answer: ["maple syrup"],
    points: 15,
  },
  2: {
    question: "What does she have around her neck?",
    answer: ["pearl necklace", "necklace"],
    points: 20,
  },
  3: {
    question: "Who is he a fan of? ",
    answer: ["lady gaga"],
    points: 15,
  },
  4: {
    question: "Where does the Fulbrok knife come from? ",
    answer: ["ikea"],
    points: 20,
  },
  5: {
    question: "Precisely how did Tokushuryu die?",
    answer: ["yakitori"],
    points: 15,
  },
  6: {
    question: "Since when has Krazen Krätz been a pesto fan?",
    answer: [
      "seven and a half",
      "since the age of seven and a half",
      "7,5 years olds",
      "7,5",
    ],
    points: 15,
  },
  7: {
    question: "What is the most precious thing for the journalist?",
    options: ["The amulet", "His notebook", "His freedom"],
    answer: ["His notebook"],
    points: 20,
  },
  8: {
    question: "Has he already travelled through time?",
    answer: ["yes"],
    points: 20,
  },
  9: {
    question:
      "What imaginary animal does the thief allude to, to distract the alchemist?",
    answer: ["vampire sheep", "vampire sheep"],
    points: 20,
  },
  10: {
    question: "What colour is his hair?",
    options: ["Blond", "Grey", "Black", "Ginger"],
    answer: ["Grey"],
    points: 20,
  },
  11: {
    question: "What is the name of the sailor?",
    answer: ["juan"],
    points: 20,
  },
  12: {
    question: "What has he been eating the last six months? ",
    options: [
      "Flowers and Courgette",
      "Fries and burger with extra bacon",
      "Manioc and bananas",
      "Pumkin seeds and bananas",
    ],
    answer: ["Manioc and bananas"],
    points: 20,
  },
  13: {
    question: "What year did the scene take place?",
    answer: ["- 11557", "-11557", "1557 bc"],
    points: 25,
  },
  14: {
    question:
      "What does the bearded guy have in his ears at the beginning of the scene?",
    answer: ["nothing"],
    points: 15,
  },
  15: {
    question:
      "Where were Rich Breath and his wife headed to before they had their terrible accident?",
    options: ["AquaPark", "Aqualand", "Aquasplash", "AquaGym"],
    answer: ["Aquasplash"],
    points: 25,
  },
  16: {
    question: "(DOC) Who is Krazen Krätz’s main rival?",
    answer: ["abdul mascarpaccio"],
    points: 20,
  },
  17: {
    question: "Which apostle does Judas accuse of treason?",
    options: ["Saint Thomas", "Saint Matthew", "Saint Peter", "Saint Andrew"],
    answer: ["Saint Peter"],
    points: 20,
  },
  18: {
    question: "What’s the punishment for apple thieves at that period? ",
    answer: ["lapidating", "lapidated", "lapidation"],
    points: 20,
  },
  19: {
    question: "What the maiden name of Lucien Frogenchtop’s wife? ",
    options: [
      "Mme Rêvedatomiseur",
      "Mme de Philipons",
      "Mme Fripon",
      "Mme Frogenchtop",
    ],
    answer: ["Mme de Philipons"],
    points: 20,
  },
  20: {
    question: "According to the Priest, which apostle touched the amulet? ",
    answer: ["thomas"],
    points: 20,
  },
  21: {
    question: "What’s the colour of his swimsuit? ",
    options: ["Black", "Grey", "Purple", "Brown"],
    answer: ["Black"],
    points: 20,
  },
  22: {
    question: "What’s his favourite film? ",
    answer: ["big blue"],
    points: 20,
  },
  23: {
    question: "What’s the name of their top speciality? ",
    options: [
      "The Gaisha Nunshaku",
      "The Twin fountains",
      "The Viagragra falls",
      "The Sister's Waterfall",
    ],
    answer: ["The Sister's Waterfall"],
    points: 20,
  },
  24: {
    question:
      "What’s the game they played to decide who would take care of the « new customer »? ",
    answer: [
      "eany meany miney moe",
      "shifume",
      "chi fou mi",
      "chi fu mi",
      "shifumi",
      "chifumi",
    ],
    points: 20,
  },
  25: {
    question: "Who is our thief?",
    answer: ["Krazen Krätz"],
    options: ["Krazen Krätz", "Rich Breath", "Sophie Duval"],
    points: 50,
  },
  26: {
    question: "Where is our thief hiding?",
    answer: ["Mesopotamia"],
    options: [
      "Peru",
      "Mythos Garage",
      "Prizoners Agency",
      "Mauritius",
      "Fort Knox Bank",
      "Mesopotamia",
      "Ikea",
    ],
    points: 50,
  },
  27: {
    question: "What was the motive for the crime?",
    answer: ["To get the best pine nuts in history"],
    options: [
      "To go to his vehicle safety inspection",
      "To buy the Ikea's Fulbrok knife on sale !",
      "To get the best pine nuts in history",
      "To get access to the biggest gold reserve in the world",
      "To see the film « Snow White » when the movie was just released !",
    ],
    points: 50,
  },
  28: {
    question: "(DOC) Precisely which material was the amulette made of? ",
    answer: ["ebony"],
    points: 30,
  },
  29: {
    question:
      "Where does our thief needs to go to to be able to recreate the amulette?",
    answer: ["mauritius"],
    points: 30,
  },
  30: {
    question: "(DOC) Is our thief a man or a woman? ",
    answer: ["A man"],
    options: ["A man", "A woman"],
    points: 30,
  },
  31: {
    question: "(DOC) Write us your transcription of the dancing men",
    answer: ["savages"],
    points: 30,
  },
  32: {
    question: "(DOC)  What is Sophie Duval's phone password?",
    answer: ["franky", "francky", "francki", "franki", "franckie", "frankie"],
    points: 20,
  },
  33: {
    question: "(Doc) What is Sophie Duval's favorite hobby?",
    answer: ["Breaking into museums"],
    options: [
      "Setting up escape plans for famous prisoners",
      "Breaking into museums",
      "Robbing banks",
      "Blackmailing blackmailers",
    ],
    points: 20,
  },
  34: {
    question: "(Doc) Where is Krazen Kratz?",
    answer: ["In Mesopotamia"],
    options: [
      "In Brazil",
      "In Mexico",
      "In Mesopotamia",
      "In China",
      "In India",
    ],
    points: 20,
  },
  35: {
    question:
      "(DOC) What does the photo found in Sophie Duval's cell phone tell us, what is her passion?",
    answer: ["The paintings of Great Masters"],
    options: [
      "Religious relics",
      "The primitive arts",
      "The paintings of Great Masters",
    ],
    points: 20,
  },
  36: {
    question: "(DOC) In which country did Rich Breath go?",
    answer: ["peru"],
    points: 20,
  },
};

export const documents = {
  1: { url: "/documents/1.jpg", title: "Reconstructed amulet" },
  2: { url: "/documents/2.jpg", title: "Components of the amulet" },
  3: { url: "/documents/3.jpg", title: "Krazen Kratz's call list" },
  4: { url: "/documents/4.jpg", title: "Rich Breath's Call list" },
  5: { url: "/documents/5.jpg", title: "Sophie Duval's call list" },
  6: { url: "/documents/6.jpg", title: "Chronological report" },
  71: { url: "/documents/71.jpg", title: "Information Sheet Krazen Kratz" },
  72: { url: "/documents/72.jpg", title: "Information Sheet Rich Breath" },
  73: { url: "/documents/73.jpg", title: "Information Sheet Sophie Duval" },
  81: { url: "/documents/81.jpg", title: "Mechanic's statement" },
  82: { url: "/documents/82.mp3", title: "Collector Philidore Khan" },
  9: { url: "/documents/9.jpg", title: "Journalist Puri", temp: true },
  10: {
    url: "/documents/10.jpg",
    title: "The Alchemist's Tessalaria",
    temp: true,
  },
  11: { url: "/documents/11.jpg", title: "Three words Sailor", temp: true },
  12: { url: "/documents/12.mp3", title: "Paleobotanist" },
  13: {
    url: "/documents/13.jpg",
    title: 'Newspaper "Le Parisien": Pesto',
    temp: true,
  },
  14: { url: "/documents/14.jpg", title: "Labo Report", temp: true },
  15: { url: "/documents/15.jpg", title: "Vision 3000" },
  16: { url: "/documents/16.jpg", title: "Statement of Louis XIV" },
  17: { url: "/documents/17.jpg", title: "Dancing men", temp: true },
  18: { url: "/documents/18.jpg", title: "Napoléon's message" },
  19: {
    url: "/documents/19.mp3",
    title: "Audio message found in Sophie Duval's phone",
  },
  20: {
    url: "/documents/20.jpg",
    title: "Strange photo found in Sophie Duval's phone",
  },
  21: {
    url: "/documents/21.jpg",
    title: "Mysterious map found at Krazen Krätz's house",
  },
  22: {
    url: "/documents/22.mp3",
    title: "Richelieu Search Audio Report",
  },
  23: {
    url: "/documents/23.jpg",
    title: "Suspects",
  },
};

const videos = {
  1: { title: "Vidéo intro", url: "https://youtu.be/rthYrLG45Jg" },
  2: { title: "Vidéo suspects", url: "https://youtu.be/rRJ5BxFxJvM" },
  3: {
    title: "Miss Frogenchtop",
    url: "https://www.youtube.com/watch?v=DVA4Fy-JZio",
  },
  4: { title: "Le Gardien", url: "https://youtu.be/tuYG7gOTSJo" },
  5: { title: "Le Journaliste", url: "https://youtu.be/ZsNF-4ud2s8" },
  6: { title: "L’alchimiste", url: "https://youtu.be/PkvUgfdUF_M" },
  7: {
    title: "Le Matelot",
    url: "https://www.youtube.com/watch?v=Nhk3uxfRx1c",
  },
  8: { title: "Hommes des cavernes", url: "https://youtu.be/RxyUZaApETU" },
  9: { title: "Judas et St Thomas", url: "https://youtu.be/1VmwcBr0xTY" },
  10: { title: "Le Prêtre", url: "https://youtu.be/LkCqZTO3xFk" },
  11: { title: "L’apneiste", url: "https://youtu.be/NDQx4pYYKjg" },
  12: { title: "Les Filles de Joie", url: "https://youtu.be/GYsDG12SkBo" },
  13: { title: "Conclusion Win", url: "https://youtu.be/_DI03SG2VK0" },
  14: { title: "Conclusion Lose 2", url: "https://youtu.be/uRxKVd0bZK4" },
  15: {
    title: "Conclusion Lose 3",
    url: "https://www.youtube.com/watch?v=kKg4AkZebvE",
  },
};

function isRight({ answer, options, value }) {
  return !options
    ? answer.some(
        (rightAnswer) =>
          slugify(value).includes(slugify(rightAnswer)) ||
          value.includes(rightAnswer)
      )
    : answer.includes(value);
}

function getScore(id, value, elapsed) {
  let { points = 0, answer, options } = questions[id];

  const isRightAnswer = isRight({ answer, options, value });

  if (id === 2 && isRightAnswer && !slugify(value).includes("perle")) {
    points = 10;
  }

  if (isRightAnswer) {
    if (elapsed < 20 * 1000) {
      return points + 8;
    } else if (elapsed < 20 * 1000) {
      return points + 8;
    } else if (elapsed < 45 * 1000) {
      return points + 6;
    } else if (elapsed < 60 * 1000) {
      return points + 4;
    } else if (elapsed < 120 * 1000) {
      return points + 2;
    }

    return points;
  }

  return 0;
}

const emptyObj = {};

const initialState = {
  isSpectator: false,
  questionIndex: 0,
  loaded: false,
  screen: "",
  answerIds: [],
  answersById: {},
  scoresById: {},
  score: 0,
  lastScore: emptyObj,
  elapsedById: {},
  timer: "",
  availableDocuments: [],
  speed: 1,
  finished: false,
};

const Store = createStore({
  initialState,
  actions: {
    initState: (initialState) => ({ setState }) =>
      setState({ ...initialState, loaded: true }),
    setSpectatorState: (nextState) => ({ setState, getState }) => {
      let diff = {};

      for (const key in nextState) {
        if (
          JSON.stringify(nextState[key]) !== JSON.stringify(getState()[key])
        ) {
          diff[key] = nextState[key];
        }
      }

      setState({ ...diff, loaded: true });
    },
    setScreen: (screen) => ({ setState, getState }) => {
      tictac.stop();

      const docsToRemove = getState()
        .availableDocuments.map((id) => ({ id, ...documents[id] }))
        .filter((it) => it.temp)
        .map((it) => it.id);

      setState({
        questionIndex: 0,
        timer: "",
        screen,
        availableDocuments: getState().availableDocuments.filter(
          (id) => !docsToRemove.includes(id)
        ),
      });
    },
    answerQuestion: (id, value) => ({ setState, getState }) => {
      const questionScore = getScore(id, value, getState().elapsedById[id]);
      const scoresById = { ...getState().scoresById, [id]: questionScore };

      const score = Object.values(scoresById).reduce(
        (acc, score) => acc + score,
        0
      );

      const nextState = {
        answerIds: [...getState().answerIds, id],
        answersById: { ...getState().answersById, [id]: value },
        scoresById,
        score,
        lastScore: { id, value, score: questionScore },
      };

      db.doc(getState().teamPath).set(
        {
          scoresById,
        },
        {
          merge: true,
        }
      );

      setState({
        ...nextState,
      });
    },
    setScore: (scoresById = {}) => ({ setState }) => {
      const score = Object.values(scoresById).reduce(
        (acc, score) => acc + score,
        0
      );

      setState({
        scoresById,
        score,
        lastScore: emptyObj,
      });
    },
    setQuestionElapsed: (id, value) => ({ setState, getState }) => {
      setState({
        elapsedById: {
          ...getState().elapsedById,
          [id]: value,
        },
      });
    },
    addDocuments: (ids) => ({ setState, getState }) => {
      setState({
        notifyDocs: ids.join(","),
        availableDocuments: [
          ...new Set([...getState().availableDocuments, ...ids]),
        ],
      });
    },
    setTimer: (timer) => ({ setState, getState }) => {
      if (!getState().isSpectator) {
        setState({
          timer,
        });
      }
    },
    setQuestionIndex: (index) => ({ setState }) => {
      setState({
        questionIndex: index,
      });
    },
    setSpeed: (speed) => ({ setState }) => {
      setState({
        speed,
      });
    },
    clearNotifyDocs: () => ({ setState }) => {
      setState({
        notifyDocs: "",
      });
    },
    clearLastScore: () => ({ setState }) => {
      setState({
        lastScore: {},
      });
    },
    setFinished: () => ({ setState }) => {
      setState({
        finished: true,
      });
    },
  },
  name: "game",
});

const useGame = createHook(Store);

function Video({ id, onEnded }) {
  const { url } = videos[id];

  return (
    <div className="video">
      <ReactPlayer
        config={{
          youtube: {
            playerVars: {
              modestbranding: 1,
            },
          },
        }}
        width={1000}
        height={605}
        playing
        url={url}
        onEnded={onEnded}
      />
    </div>
  );
}

function Button(props) {
  return <button className="btn" {...props} />;
}

function Document({ id, readDocuments, readDocument }) {
  const [isOpen, setIsOpen] = useState(false);
  const { title, url } = documents[id];
  const isRead = readDocuments.includes(id);

  useEffect(() => {
    if (isOpen) {
      readDocument(id);
    }
  }, [id, isOpen]);

  return (
    <>
      <Modal size="xl" isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          {url.endsWith(".mp3") ? (
            <audio controls src={url} />
          ) : (
            <Image src={url} alt={title} />
          )}
        </ModalContent>
      </Modal>
      <div
        className={
          isRead
            ? "animate__animated animate__bounceIn document read"
            : "animate__animated animate__bounceIn document"
        }
        onClick={() => setIsOpen(true)}
      >
        <div className="document-title">{title}</div>
      </div>
    </>
  );
}

function Documents() {
  const [readDocuments, setReadDocuments] = useState([]);
  const [{ availableDocuments }] = useGame();

  function readDocument(id) {
    setReadDocuments((prev) => [...prev, id]);
  }

  return (
    <div className="documents">
      {[...availableDocuments].reverse().map((id) => (
        <Document
          key={id}
          id={id}
          readDocuments={readDocuments}
          readDocument={readDocument}
        />
      ))}
    </div>
  );
}

function Question({ id, onSubmit }) {
  const [elapsed, setElapsed] = useState(0);
  const { question, options } = questions[id];
  const [{ answersById }, { answerQuestion, setQuestionElapsed }] = useGame();
  const answer = answersById[id];

  useEffect(() => {
    const to = setInterval(() => {
      setElapsed((prevElapsed) => {
        return prevElapsed + 1000;
      });
    }, 1000);

    return () => {
      clearInterval(to);
    };
  }, []);

  useEffect(() => {
    setQuestionElapsed(id, elapsed);
  }, [elapsed, setQuestionElapsed, id]);

  return (
    <div className={`question ${answer ? "question-complete" : ""}`}>
      <div className="question-title">{question}</div>
      <Formik
        isInitialValid={false}
        initialValues={{ answer }}
        validationSchema={Yup.object().shape({
          answer: Yup.string().required("Answer is required"),
        })}
        onSubmit={(value) => {
          answerQuestion(id, value.answer, elapsed);
          onSubmit();
        }}
      >
        {({ setFieldValue, isValid }) => (
          <Form className="question-form">
            {question && (
              <>
                {options ? (
                  <Field name="answer">
                    {({ field }) => (
                      <RadioGroup
                        name="anwser"
                        value={field.value}
                        onChange={(value) => {
                          setFieldValue("answer", value);
                        }}
                      >
                        <Stack>
                          {options.map((option, i) => (
                            <Radio
                              key={i}
                              name="anwser"
                              disabled={!!answer}
                              value={option}
                              isChecked={field.value === option}
                            >
                              {option}
                            </Radio>
                          ))}
                        </Stack>
                      </RadioGroup>
                    )}
                  </Field>
                ) : (
                  <Field
                    disabled={!!answer}
                    name="answer"
                    className="field-text"
                    type="text"
                    autoComplete="off"
                    placeholder="Your answer"
                  />
                )}
                <div className="submit-wrapper">
                  <button
                    type="submit"
                    className="btn"
                    disabled={!!answer}
                    style={{ display: isValid ? "block" : "none" }}
                  >
                    Save
                  </button>
                </div>
              </>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
}

function QuestionsGroup({
  duration,
  questions = [],
  documents = [],
  nextScreen,
}) {
  const [
    { questionIndex, answersById, scoresById },
    { setScreen, addDocuments, setQuestionIndex },
  ] = useGame();

  const questionId = questions[questionIndex];

  useEffect(() => {
    if (documents.length) {
      addDocuments(documents);
    }
    //eslint-disable-next-line
  }, []);

  function questionStatus(id) {
    if (!answersById[id]) {
      return null;
    }
    if (scoresById[id]) {
      return <CheckIcon size="16px" color="green.500" />;
    }

    return <CloseIcon size="16px" color="red.500" />;
  }

  return (
    <>
      <Timer duration={duration} onEnded={() => setScreen(nextScreen)} />
      {questions.length > 0 && (
        <>
          <Question
            key={questionId}
            id={questionId}
            onSubmit={() => {
              if (questionIndex <= questions.length - 2) {
                setQuestionIndex(questionIndex + 1);
              }
            }}
          />
          {questionIndex > 0 && (
            <div
              className="btn-nav-left"
              onClick={() => setQuestionIndex(questionIndex - 1)}
            >
              <ChevronLeftIcon />
            </div>
          )}
          {questionIndex < questions.length - 1 && (
            <div
              className="btn-nav-right"
              onClick={() => setQuestionIndex(questionIndex + 1)}
            >
              <ChevronRightIcon />
            </div>
          )}
          <div className="btn-navs">
            {questions.map((id, i) => (
              <div
                key={i}
                className={
                  questionIndex === i
                    ? "btn-navs-item current"
                    : "btn-navs-item"
                }
                onClick={() => setQuestionIndex(i)}
              >
                <div className="btn-navs-num">{i}</div>
                <div>{questionStatus(id)}</div>
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
}

function QuestionFinal({ duration, questions = [], documents = [] }) {
  const [
    { answersById, scoresById, questionIndex = 0 },
    { setScreen, addDocuments, setQuestionIndex, setFinished },
  ] = useGame();

  const questionId = questions[questionIndex];

  useEffect(() => {
    if (documents.length) {
      addDocuments(documents);
    }
    //eslint-disable-next-line
  }, []);

  function questionStatus(id) {
    if (!answersById[id]) {
      return null;
    }
    if (scoresById[id]) {
      return <CheckIcon size="16px" color="green.500" />;
    }

    return <CloseIcon size="16px" color="red.500" />;
  }

  function lastQuestionTransition() {
    if (answersById[25] === "Krazen Krätz") {
      setScreen("videoWin");
    } else if (answersById[25] === "Rich Breath") {
      setScreen("videoLoose2");
    } else if (answersById[25] === "Sophie Duval") {
      setScreen("videoLoose3");
    } else {
      setScreen("videoLoose3");
    }
    setFinished();
  }

  return (
    <>
      <Timer duration={duration} onEnded={lastQuestionTransition} />
      {questions.length > 0 && (
        <>
          <Question
            key={questionId}
            id={questionId}
            onSubmit={() => {
              if (questionIndex <= questions.length - 2) {
                setQuestionIndex(questionIndex + 1);
              }
            }}
          />
          {questionIndex > 0 && (
            <div
              className="btn-nav-left"
              onClick={() => setQuestionIndex(questionIndex - 1)}
            >
              <ChevronLeftIcon />
            </div>
          )}
          {questionIndex < questions.length - 1 && (
            <div
              className="btn-nav-right"
              onClick={() => setQuestionIndex(questionIndex + 1)}
            >
              <ChevronRightIcon />
            </div>
          )}
          <div className="btn-navs">
            {questions.map((id, i) => (
              <div
                key={i}
                className={
                  questionIndex === i
                    ? "btn-navs-item current"
                    : "btn-navs-item"
                }
                onClick={() => setQuestionIndex(i)}
              >
                <div className="btn-navs-num">{i}</div>
                <div>{questionStatus(id)}</div>
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
}

function GameEnded() {
  const [{ score }] = useGame();

  return (
    <div className="game-ended">
      <div>
        <h2>The game is over!</h2>
        <p>Thank you for your excellent work!</p>
        <h3>Your final score is {score} points!</h3>
        <p>
          This is a very honorable score! Did the other teams do so well? I’m
          not sure!
        </p>
        <p>
          The podium will be revealed in a moment. If a facilitator accompanies
          your for your session, they will bring you back to the main room in a
          minute. And then we will procede… to the final scoreboard!
        </p>
      </div>
    </div>
  );
}

function Timer({ duration, onEnded, stressSound = true }) {
  const [{ speed, isSpectator }, { setTimer }] = useGame();
  const [remaining, setRemaining] = useState(duration);

  useEffect(() => {
    if (!isSpectator) {
      tictac.stop();
      ding.play();

      const to = setInterval(() => {
        setRemaining((prevRemaining) => {
          const nextRemainning = Math.max(0, prevRemaining - 1000);

          if (stressSound && nextRemainning < 30 * 1000 && !tictac.playing()) {
            tictac.play();
          }
          return Math.max(0, prevRemaining - 1000);
        });
      }, 1000 / speed);

      return () => {
        tictac.stop();
        clearInterval(to);
      };
    }
  }, [speed, isSpectator]);

  useEffect(() => {
    if (!remaining && onEnded && !isSpectator) {
      onEnded();
    }
  }, [remaining, onEnded, isSpectator]);

  useEffect(() => {
    if (!isSpectator) {
      setTimer(sf.convert(remaining / 1000).format("MM:SS"));
    }
  }, [remaining, setTimer, isSpectator]);

  return null;
}

const Screens = {
  intro$1: ({ setScreen }) => (
    <Video id={1} onEnded={() => setScreen("intro$2")} />
  ),
  intro$2: () => (
    <QuestionsGroup
      duration={7 * 60 * 1000}
      nextScreen="intro$4"
      questions={[15, 5, 6]}
      documents={[23, 1, 71, 72, 73, 19]}
    />
  ),
  intro$3: ({ setScreen }) => (
    <Video id={2} onEnded={() => setScreen("intro$4")} />
  ),
  intro$4: ({ setScreen }) => (
    <div className="choice">
      <Timer
        stressSound={false}
        duration={30 * 1000}
        onEnded={() => setScreen("frogenchtop$1")}
      />
      <p>
        You now have to chose between various individuals who have witnessed
        something unusual… There is no wrong choice : pick someone according to
        your guts (an inspector’s guts can be so useful during an investigation
        ...)
      </p>
      <div className="choice-wrapper">
        <Button onClick={() => setScreen("frogenchtop$1")}>
          Miss FrogenChtop
        </Button>
        <Button onClick={() => setScreen("gardien$1")}>The Caretaker</Button>
      </div>
    </div>
  ),
  frogenchtop$1: ({ setScreen }) => (
    <Video id={3} onEnded={() => setScreen("frogenchtop$2")} />
  ),
  frogenchtop$2: () => (
    <QuestionsGroup
      duration={10 * 60 * 1000}
      nextScreen="frogenchtop$3"
      questions={[1, 2, 28, 32]}
      documents={[2, 6, 18]}
    />
  ),
  frogenchtop$3: ({ setScreen }) => (
    <div className="choice">
      <Timer
        stressSound={false}
        duration={30 * 1000}
        onEnded={() => setScreen("alchimiste$1")}
      />
      <p>Who do you want to listen to now? The Alchemist or the Journalist?</p>
      <div className="choice-wrapper">
        <Button onClick={() => setScreen("alchimiste$1")}>The alchemist</Button>
        <Button onClick={() => setScreen("journaliste$1")}>
          The Journalist
        </Button>
      </div>
    </div>
  ),
  alchimiste$1: ({ setScreen }) => (
    <Video id={6} onEnded={() => setScreen("alchimiste$2")} />
  ),
  alchimiste$2: () => (
    <QuestionsGroup
      duration={14 * 60 * 1000}
      nextScreen="alchimiste$3"
      questions={[9, 10, 29, 33, 34]}
      documents={[81, 10, 19, 21]}
    />
  ),
  alchimiste$3: ({ setScreen }) => (
    <div className="choice">
      <Timer
        stressSound={false}
        duration={30 * 1000}
        onEnded={() => setScreen("prehistoire$1")}
      />
      <p>
        What a long way we have come ! But it’s not over yet … You may have had
        some choices to make earlier, but not this time! Indeed, we noticed a
        disruption in the space-time continuum...
      </p>
    </div>
  ),
  journaliste$1: ({ setScreen }) => (
    <Video id={5} onEnded={() => setScreen("journaliste$2")} />
  ),
  journaliste$2: () => (
    <QuestionsGroup
      duration={14 * 60 * 1000}
      nextScreen="journaliste$3"
      questions={[7, 8, 29, 33, 34]}
      documents={[81, 10, 19, 21]}
    />
  ),
  journaliste$3: ({ setScreen }) => (
    <div className="choice">
      <Timer
        stressSound={false}
        duration={30 * 1000}
        onEnded={() => setScreen("prehistoire$1")}
      />
      <p>
        What a long way we have come ! But it’s not over yet … You may have had
        some choices to make earlier, but not this time! Indeed, we noticed a
        disruption in the space-time continuum...
      </p>
    </div>
  ),
  prehistoire$1: ({ setScreen }) => (
    <Video id={8} onEnded={() => setScreen("prehistoire$2")} />
  ),
  prehistoire$2: () => (
    <QuestionsGroup
      duration={8 * 60 * 1000}
      nextScreen="prehistoire$3"
      questions={[13, 14, 16, 30, 35]}
      documents={[12, 13, 14, 20]}
    />
  ),
  prehistoire$3: ({ setScreen }) => (
    <div className="choice">
      <Timer
        stressSound={false}
        duration={30 * 1000}
        onEnded={() => setScreen("judas$1")}
      />
      <p>
        And for the last time... 3 characters have an important information for
        you, which one would you like to listen to?
      </p>
      <div className="choice-wrapper">
        <Button onClick={() => setScreen("judas$1")}>
          Judas and St Thomas
        </Button>
        <Button onClick={() => setScreen("pretre$1")}>The Priest</Button>
        <Button onClick={() => setScreen("apneiste$1")}>The Free Diver</Button>
      </div>
    </div>
  ),
  judas$1: ({ setScreen }) => (
    <Video id={9} onEnded={() => setScreen("judas$2")} />
  ),
  judas$2: () => (
    <QuestionsGroup
      duration={8 * 60 * 1000}
      nextScreen="final$1"
      questions={[17, 18, 31, 36]}
      documents={[15, 16, 17, 19, 22]}
    />
  ),
  pretre$1: ({ setScreen }) => (
    <Video id={10} onEnded={() => setScreen("pretre$2")} />
  ),
  pretre$2: () => (
    <QuestionsGroup
      duration={8 * 60 * 1000}
      nextScreen="final$1"
      questions={[19, 20, 31, 36]}
      documents={[15, 16, 17, 19, 22]}
    />
  ),
  apneiste$1: ({ setScreen }) => (
    <Video id={11} onEnded={() => setScreen("apneiste$2")} />
  ),
  apneiste$2: () => (
    <QuestionsGroup
      duration={10 * 60 * 1000}
      nextScreen="final$1"
      questions={[21, 22, 31, 36]}
      documents={[15, 16, 17, 22, 19]}
    />
  ),
  gardien$1: ({ setScreen }) => (
    <Video id={4} onEnded={() => setScreen("gardien$2")} />
  ),
  gardien$2: () => (
    <QuestionsGroup
      duration={10 * 60 * 1000}
      nextScreen="gardien$3"
      questions={[3, 4, 28, 32]}
      documents={[2, 6, 71, 72, 73, 18]}
    />
  ),
  gardien$3: ({ setScreen }) => (
    <div className="choice">
      <Timer
        stressSound={false}
        duration={30 * 1000}
        onEnded={() => setScreen("alchimiste$1")}
      />
      <p>Who do you want to listen to now? The Alchemist or the Sailor?</p>
      <div className="choice-wrapper">
        <Button onClick={() => setScreen("alchimiste$1")}>The Alchemist</Button>
        <Button onClick={() => setScreen("matelot$1")}>The Sailor</Button>
      </div>
    </div>
  ),
  matelot$1: ({ setScreen }) => (
    <Video id={7} onEnded={() => setScreen("matelot$2")} />
  ),
  matelot$2: () => (
    <QuestionsGroup
      duration={14 * 60 * 1000}
      nextScreen="matelot$3"
      questions={[11, 12, 29, 33, 34]}
      documents={[81, 10, 19, 21]}
    />
  ),
  matelot$3: ({ setScreen }) => (
    <div className="choice">
      <Timer
        stressSound={false}
        duration={30 * 1000}
        onEnded={() => setScreen("prehistoireAlt$1")}
      />
      <p>
        What a long way we have come ! But it's not over yet... You have had to
        make choices before now, but not this time ! Indeed, we have noticed a
        disruption in the space-time continuum...
      </p>
    </div>
  ),
  prehistoireAlt$1: ({ setScreen }) => (
    <Video id={8} onEnded={() => setScreen("prehistoireAlt$2")} />
  ),
  prehistoireAlt$2: () => (
    <QuestionsGroup
      duration={8 * 60 * 1000}
      nextScreen="prehistoireAlt$3"
      questions={[13, 14, 16, 30, 35]}
      documents={[12, 13, 14, 20]}
    />
  ),
  prehistoireAlt$3: ({ setScreen }) => (
    <div className="choice">
      <Timer
        stressSound={false}
        duration={30 * 1000}
        onEnded={() => setScreen("judas$1")}
      />
      <p>
        And for the last time... 3 characters have an important information for
        you, which one would you like to listen to?
      </p>
      <div className="choice-wrapper">
        <Button onClick={() => setScreen("filles$1")}>The Loose Women</Button>
        <Button onClick={() => setScreen("pretre$1")}>The Priest</Button>
        <Button onClick={() => setScreen("apneiste$1")}>The Free Diver</Button>
      </div>
    </div>
  ),
  filles$1: ({ setScreen }) => (
    <Video id={12} onEnded={() => setScreen("filles$2")} />
  ),
  filles$2: () => (
    <QuestionsGroup
      duration={8 * 60 * 1000}
      nextScreen="final$1"
      questions={[23, 24, 31, 36]}
      documents={[15, 16, 17, 19, 22]}
    />
  ),
  final$1: ({ setScreen }) => (
    <div className="choice">
      <Timer duration={2 * 60 * 1000} onEnded={() => setScreen("final$2")} />
      <p>
        Our investigation is coming to en end.. Yes, already ! We give you 2
        minutes to share your assumptions with your team mates...
      </p>
    </div>
  ),
  final$2: () => (
    <div className="choice">
      <p>
        The mystery surrounding Miss Frogenchtop's amulette will soon be lifted
        thanks to you! Answer quickly, but answer well !
      </p>
      <QuestionFinal duration={3 * 60 * 1000} questions={[25, 26, 27]} />
    </div>
  ),
  videoWin: ({ setScreen }) => (
    <Video id={13} onEnded={() => setScreen("final$3")} />
  ),
  videoLoose2: ({ setScreen }) => (
    <Video id={14} onEnded={() => setScreen("final$3")} />
  ),
  videoLoose3: ({ setScreen }) => (
    <Video id={15} onEnded={() => setScreen("final$3")} />
  ),
  final$3: () => <GameEnded />,
};

function Game({ team }) {
  const history = useHistory();
  const [started, setStarted] = useState(false);
  const toast = useToast();
  const [
    state,
    { setScreen, initState, setSpeed, clearNotifyDocs, clearLastScore },
  ] = useGame();
  const {
    hash,
    teamPath,
    isSpectator,
    screen,
    score,
    timer,
    lastScore,
    notifyDocs,
    speed,
  } = state;
  const Screen = Screens[screen];

  const notifyDocuments = useCallback(() => {
    if (notifyDocs) {
      toast({
        duration: 4000,
        render: () => (
          <div className="toast">
            <div className="toast-title">New documents had been added !</div>
          </div>
        ),
      });

      clearNotifyDocs();
    }
  }, [toast, notifyDocs, clearNotifyDocs]);

  const notifyScore = useCallback(() => {
    if (lastScore.score !== undefined) {
      toast({
        duration: 4000,
        render: () => (
          <div className="toast">
            <div className="toast-title">
              {lastScore.score ? "Good answer !" : "Bad answer !"}
            </div>
            <div className="toast-content">
              {lastScore.score
                ? `You have scored ${lastScore.score} points.`
                : "You haven't scored any points"}
            </div>
          </div>
        ),
      });

      clearLastScore();
    }
  }, [toast, lastScore, clearLastScore]);

  useEffect(() => {
    notifyScore();
  }, [notifyScore]);

  useEffect(() => {
    notifyDocuments();
  }, [notifyDocuments]);

  global.debug = window.location.href.includes("DEBUG");
  global.god = window.location.href.includes("GOD");

  return (
    <>
      {started ? (
        <div className={isSpectator ? "game-spectator" : ""}>
          <GameTheme
            team={team}
            score={score}
            screen={
              <div className="screen">
                {Screen ? <Screen setScreen={setScreen} /> : null}
              </div>
            }
            documents={<Documents />}
            timer={timer}
          />
        </div>
      ) : (
        <GameTheme
          screen={
            <div className="screen">
              <div className="choice">
                <p>
                  {isSpectator
                    ? "Ready ?"
                    : `Wait until all players have clicked "Let's go!", then click "Start" to start the game.`}
                </p>
                <div className="choice-wrapper">
                  <Button
                    onClick={() => {
                      if (!isSpectator) {
                        setScreen(screen || "intro$1");
                      }
                      setStarted(true);
                    }}
                  >
                    {isSpectator ? "Let's go !" : "Start"}
                  </Button>
                </div>
              </div>
            </div>
          }
        />
      )}
      {!isSpectator && (
        <>
          <div className="team-code">Code: {hash}</div>
          <div
            className="quit"
            onClick={() => {
              if (
                window.confirm(
                  "Are you sure you want to quit ? The game will be lost !"
                )
              ) {
                history.push("/login-master");
              }
            }}
          >
            Quit
          </div>
          {global.debug && (
            <div className="nav">
              <Select
                size="xs"
                borderColor="transparent"
                onChange={(e) => setScreen(e.target.value)}
                value={screen}
              >
                <option></option>
                {Object.keys(Screens).map((it) => (
                  <option key={it}>{it}</option>
                ))}
              </Select>
            </div>
          )}
          {global.god && (
            <div className="nav">
              <Select
                size="xs"
                borderColor="transparent"
                onChange={(e) => setScreen(e.target.value)}
                value={screen}
              >
                <option></option>
                {Object.keys(Screens).map((it) => (
                  <option key={it}>{it}</option>
                ))}
              </Select>
              <Select
                size="xs"
                borderColor="transparent"
                onChange={(e) =>
                  setSpeed(e.target.value === "vitesse normale" ? 1 : 15)
                }
                value={speed === 1 ? "vitesse normale" : "vitesse rapide"}
              >
                <option>vitesse normale</option>
                <option>vitesse rapide</option>
              </Select>
              <ChakraButton
                size="xs"
                onClick={() => initState({ ...initialState, teamPath })}
              >
                RESET
              </ChakraButton>
            </div>
          )}
        </>
      )}
    </>
  );
}

function LoadMasterGame({ teamPath }) {
  const history = useHistory();
  const [{ name, loaded }, { initState, setScore }] = useGame();

  if (loaded && !name) {
    history.push("/login");
  }

  useEffect(() => {
    db.doc(teamPath)
      .get()
      .then((doc) => {
        const data = doc.data();
        initState({ ...initialState, ...data, teamPath });
      });
  }, [teamPath]);

  useEffect(() => {
    db.doc(teamPath).onSnapshot((doc) => {
      const { scoresById } = doc.data();
      setScore(scoresById);
    });
  }, [teamPath]);

  if (loaded) {
    return <Game team={name.replace(/\s\(.*\)$/, "")} />;
  }

  return null;
}

function LoadSpectatorGame({ teamPath }) {
  const [{ name, loaded }, { setSpectatorState }] = useGame();

  useEffect(() => {
    db.doc(teamPath).onSnapshot((doc) => {
      const data = doc.data();
      setSpectatorState({
        ...initialState,
        ...data,
        teamPath,
        isSpectator: true,
      });
    });
  }, [teamPath]);

  if (loaded) {
    return <Game team={name.replace(/\s\(.*\)$/, "")} />;
  }

  return null;
}

export default function LoadGameProxy() {
  useBeforeunload((event) => {
    if (process.env.NODE_ENV === "production") {
      event.preventDefault();
    }
  });

  const [teamPath, setTeamPath] = useState();
  const params = useParams();

  useEffect(() => {
    db.collection("codes")
      .where("hash", "==", params.hash)
      .get()
      .then(({ docs }) => {
        setTeamPath(docs[0].data().teamPath);
      });
  }, [params.hash]);

  if (!teamPath) {
    return null;
  }

  return params.type === "spectator" ? (
    <LoadSpectatorGame teamPath={teamPath} />
  ) : (
    <LoadMasterGame teamPath={teamPath} />
  );
}
