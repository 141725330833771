import React from "react";
import ReactDOM from "react-dom";
import { FirestoreProvider } from "react-firestore";
import { ChakraProvider } from "@chakra-ui/react";
import ImagePreloader from "image-preloader";
import Routes from "./Routes";
import { documents } from "./Game";
import firebase from "./config";

import "./index.css";

const preloader = new ImagePreloader();
const urls = Object.values(documents)
  .map((it) => it.url)
  .filter((url) => url.endsWith(".jpg"));

preloader.preload(...urls).then(() => {
  ReactDOM.render(
    <ChakraProvider>
      <FirestoreProvider firebase={firebase}>
        <Routes />
      </FirestoreProvider>
    </ChakraProvider>,
    document.getElementById("root")
  );
});
