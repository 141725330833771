import themeHome from "./theme-home.svg";
import bg from "./bg-home.png";
import "./theme.css";

export default function Home({ form }) {
  return (
    <div className="wrapper home">
      <img alt="Theme" src={themeHome} className="svg bg" />
      <svg className="svg" viewBox="0 0 1927.41 1003.88">
        <image href={bg} height="1003.88" width="1927.41" />
        <foreignObject x="45" y="920" width="1700" height="80">
          {form}
        </foreignObject>
      </svg>
    </div>
  );
}
