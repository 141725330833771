import theme from "./theme.svg";
import themeBg from "./theme-bg.svg";
import "./theme.css";

export default function Game({
  team = "Demo",
  score = 0,
  timer,
  screen,
  documents,
}) {
  return (
    <div className="wrapper">
      <img alt="Theme" src={themeBg} className="svg bg" />
      <svg className="svg" viewBox="0 0 1927.41 1003.88">
        <text
          className="metric"
          transform="translate(980 118)"
          dominantBaseline="middle"
          textAnchor="middle"
        >
          {team}
        </text>
        <text
          className="metric"
          transform="translate(1380 118)"
          dominantBaseline="middle"
          textAnchor="middle"
        >
          {score}
        </text>
        <text
          className="metric"
          transform="translate(1730 118)"
          dominantBaseline="middle"
          textAnchor="middle"
        >
          {timer}
        </text>
        <foreignObject x="90" y="355" width="1000" height="605">
          {screen}
        </foreignObject>
        <foreignObject x="1200" y="260" width="600" height="620">
          {documents}
        </foreignObject>
      </svg>
      <img alt="Theme" src={theme} className="svg overlay" />
    </div>
  );
}
